app.controller('WidgetController', ['$rootScope', '$scope', '$log', '$mdDialog', '$http', '$q', function ($rootScope, $scope, $log, $mdDialog, $http, $q) {
    $log.debug('WidgetController');

    var handleAjaxSubmission = makeAjaxHandler($mdDialog, $http);

    $scope.serviceWindows = window.__SERVICE_WINDOWS__;

    $scope.serviceTypes = window.__SERVICE_TYPES__;

    $scope.stripeError = null;

    $scope.submitting = false;

    $scope.request = {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        place_id: '',
        plain_text: '',
        zipcode: '',
        service_type: '',
        service_window: '',
        notes: '',
        credit_card: '',
        exp_month: '',
        exp_year: '',
        cvc: '',
        send_sms: true
    };

    // ----- Address Auto Complete ----
    addressAutoComplete('address', function (place) {
        $scope.request.place_id = place.place_id;
        $scope.request.plain_text = place.formatted_address;

        place.address_components.forEach(function(addressComponent) {
            if (addressComponent.types.indexOf("postal_code") !== -1) {
                $scope.request.zipcode = addressComponent.long_name;
            }
        });

        $scope.$apply();
    });
    document.getElementById("address").placeholder = "Your Address";
    // ----- Address Auto Complete ----

    window.__SERVICE_WINDOWS__.sort(function(a, b) {
        if (moment(a.date + ' ' + a.start).toDate() > moment(b.date + ' ' + b.start).toDate()) {
            return 1;
        } else {
            return -1;
        }
    });

    $scope.getServiceWindowsForServiceType = function() {
        if (!$scope.request.service_type) return [];

        var filteredOnServiceType = $scope.serviceWindows.filter(function(serviceWindow) {
            return serviceWindow.service_type == $scope.request.service_type;
        });

        return filteredOnServiceType.reduce(function(accumulator, serviceWindow) {
            var alreadyInAccumulator = false;

            accumulator.forEach(function (sw) {
                if (sw.date === serviceWindow.date &&
                    sw.start === serviceWindow.start &&
                    sw.end === serviceWindow.end &&
                    sw.service_type == serviceWindow.service_type) {
                    alreadyInAccumulator = true;
                }
            });

            if (!alreadyInAccumulator) {
                accumulator.push(serviceWindow);
                return accumulator;
            } else {
                return accumulator;
            }
        }, []);
    };

    $scope.gotoStep = function(step) {
        document.getElementById("widget-panes").style.left = String(-step * 100) + "%";

        var availableServiceTypesByZipcode = [];

        window.__SERVICE_TYPES__.forEach(function (serviceType) {
            serviceType.zipcodes.split(/\s*,\s*/g).forEach(function(zipcode) {
                if ($scope.request.zipcode === zipcode) {
                    availableServiceTypesByZipcode.push(serviceType);
                }
            });
        });

        $scope.serviceTypes = availableServiceTypesByZipcode;

        $scope.serviceTypes.forEach(function(serviceType) {
            if (serviceType.id == $scope.request.service_type) {
                $scope.request.requires_payment = serviceType.requires_payment;
                $scope.request.payment_amount = serviceType.payment_amount;
            }
        });
    };

    $scope.step0Complete = function () {
        try {
            return $scope.request.first_name.length > 0 &&
                $scope.request.last_name.length > 0 &&
                $scope.request.plain_text.length > 0 &&
                $scope.request.email.length > 0 &&
                $scope.request.phone.length > 0;
        } catch (e) {
            return false;
        }
    };

    $scope.step1Complete = function () {
        try {
            return $scope.request.service_type.length > 0 &&
                $scope.request.service_window.length > 0;
        } catch (e) {
            return false;
        }
    };

    $scope.submitAppointment = function () {
        $scope.submitting = true;
        $scope.stripeError = null;

        if ($scope.request.requires_payment) {
            Stripe.card.createToken({
                number: $scope.request.credit_card,
                cvc: $scope.request.cvc,
                exp_month: $scope.request.exp_month,
                exp_year: $scope.request.exp_year
            }, stripeResponseHandler);
        } else {
            doSubmission();
        }
    };

    function stripeResponseHandler (statusCode, response) {
        if (statusCode !== 200) {
            $scope.submitting = false;
            $scope.stripeError = response.error.message;
            $scope.$apply();
        } else {
            doSubmission(response);
        }
    }

    function doSubmission (stripeResponse) {

        var token;

        if (stripeResponse) {
            token = stripeResponse.id;
        } else {
            token = null;
        }

        var requestData = {
            // contact information
            first_name: $scope.request.first_name,
            last_name: $scope.request.last_name,
            email: $scope.request.email,
            phone: $scope.request.phone,
            send_sms: $scope.request.send_sms,

            // address
            place_id: $scope.request.place_id,
            plain_text: $scope.request.plain_text,

            // appointment notes
            notes: $scope.request.notes,

            // service type and window selected
            service_type: Number($scope.request.service_type),
            service_window: JSON.parse($scope.request.service_window),

            token: token
        };

        handleAjaxSubmission('POST', '/widget', function(res) {
            $scope.submitting = false;
            $scope.gotoStep(3);
        }, requestData);
    }
}]);
app.controller('MasterController', ['$scope', '$log', '$mdToast', '$mdDialog', '$http', function ($scope, $log, $mdToast, $mdDialog, $http) {
    $log.debug('Loading MasterController');

    var handleAjaxSubmission = makeAjaxHandler($mdDialog, $http);

    var toastShown = false;

    function showActionToast (viewAppointmentUrl) {
        $mdToast.show({
            template: $('#appointment-toast').html(),
            hideDelay: false,
            position: 'top right',
            controller: function ToastController($scope, $mdToast) {
                $scope.closeToast = function () {
                    $mdToast.hide();
                    toastShown = false;
                };

                $scope.viewAppointment = function () {
                    window.location.href = viewAppointmentUrl;
                };
            }
        })
    }

    var lastCheck;
    if (localStorage.lastAppointmentCheck) {
        lastCheck = moment(localStorage.lastAppointmentCheck);
    } else {
        lastCheck = moment();
        localStorage.setItem("lastAppointmentCheck", lastCheck.format('YYYY-MM-DD HH:mm:ss'));
    }

    function checkForNewAppointment () {
        var formattedLastCheck = lastCheck.format('YYYY-MM-DD HH:mm:ss');

        handleAjaxSubmission('GET', '/admin/ajax-new-appointment-check/' + formattedLastCheck, function(res) {
            var newAppointment = JSON.parse(res.data);

            if (newAppointment) {

                // Don't show new toasts, until one is closed.
                if (!toastShown) {
                    showActionToast('/admin/new-appointments/' + lastCheck.subtract(20, 'minutes').format('YYYY-MM-DD HH:mm:ss'));
                    toastShown = true;
                }

                // Reload the schedule.
                if (window.$schedule) {
                    window.$schedule.fullCalendar('refetchEvents');
                }
            }
        });

        lastCheck = moment();
        localStorage.setItem("lastAppointmentCheck", lastCheck.format('YYYY-MM-DD HH:mm:ss'));
    }

    $(function () {
        setTimeout(function () {
            if (window.location.href.indexOf('widget') === -1) {
                setInterval(checkForNewAppointment, 60 * 1000);
            }
        }, 30 * 1000);
    });
}]);

app.controller('AdminCustomerController', ['$scope', '$log', '$mdDialog', '$http', '$q', function ($scope, $log, $mdDialog, $http, $q) {
    $log.debug('Loading AdminCustomerController');

    var handleAjaxSubmission = makeAjaxHandler($mdDialog, $http);

    $scope.newAddress = window.__CREATING_NEW_CUSTOMER;
    $scope.customerId = window.__CUSTOMER_ID;

    $scope.selectedAddress = window.__SELECTED_ADDRESS__;

    $scope.createCustomer = function() {
        window.location.href = '/admin/customer'
    };

    $scope.editCustomer = function(customerId) {
        window.location.href = '/admin/customer/' + customerId;
    };

    $scope.viewCustomerAppointments = function(customerId) {
        window.location.href = '/admin/customer-appointments/' + customerId;
    };

    $scope.viewCustomerCharges = function(customerId) {
        window.location.href = '/admin/charges/' + customerId;
    };

    $scope.deleteCustomer = function(customerId) {
        var confirm = $mdDialog.confirm()
            .title('Delete customer?')
            .htmlContent('This will permanently delete this customer. Are you sure?')
            .ariaLabel('delete customer')
            .ok('Yes, delete forever')
            .cancel('Cancel');

        $mdDialog.show(confirm).then(function() {
            handleAjaxSubmission('DELETE', '/admin/customer/' + customerId, true);
        });
    };
    
    $scope.submitCustomer = function() {
        if ($scope.customerForm.$pristine || !$scope.customerForm.$valid) {
            return;
        }
        $scope.customerForm.$submitted = true;
        $('[name="customerForm"]').submit();
    };

    /**
     * Opens the reports menu.
     *
     * @param {object} mdOpenMenu - The menu to open.
     *
     * @param {object} ev - The menu to open.
     */
    $scope.openRoleMenu = function($mdOpenMenu, ev) {
        $mdOpenMenu(ev);
    };


    // ----- Address Auto Complete ----
    addressAutoComplete('address', function (place) {
        $scope.selectedAddress = {};
        $scope.selectedAddress.place_id = place.place_id;
        $scope.selectedAddress.plain_text = place.formatted_address;
        $scope.$apply();
    }, 'Primary Address');
    // ----- Address Auto Complete ----


    Mousetrap($('[name="search"]')[0]).bind('enter', function() {
        $('[name="search"]').submit();
    });

    $scope.findAddress = function (query) {
        var deferred = $q.defer();
        (function (query, deferred) {
            makeCustomerAddressFinder($mdDialog, $http, function(data) {
                deferred.resolve(data);
            })($scope.customerId, query);
        })(query, deferred);
        return deferred.promise;
    };

    $scope.createNewAddress = function (addressQuery) {
        $scope.newAddress = true;
        setTimeout(function() {
            $scope.addressQuery = "";
            document.getElementById('address').value= addressQuery;
            document.getElementById('address').focus();
        }, 100);
    };

    var getCustomersAjax = makeAjaxHandler($mdDialog, $http);

    window.testGetCustomerAjax = function (query) {
        getCustomersAjax('GET', '/admin/find-customer?q=' + encodeURIComponent(query) , function (res) {
            console.log(res);
        });
    }

}]);

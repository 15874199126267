app.controller('ServiceWindowController', ['$scope', '$log', '$mdDialog', '$http', function ($scope, $log, $mdDialog, $http) {

    $schedule = $('#schedule');

    var handleAjaxSubmission = makeAjaxHandler($mdDialog, $http);

    function createTitleString(serviceTypes) {
        var serviceArr = [];
        __SERVICE_TYPES__.forEach(function(serviceType) {
            if (serviceTypes[serviceType.id]) {
                serviceArr.push(serviceType.name);
            }
        });
        return _.uniq(serviceArr).join(', ');
    }

    $scope.submit = function() {
        $scope.serviceWindowForm.$submitted = true;

        var serviceWindows = [];

        $('#schedule')
            .fullCalendar('clientEvents')
            .map(function(calEvent) {
                // Create a service window for each service type.
                for (var serviceTypeId in calEvent.serviceTypes) {

                    // If this service type isn't selected, move on to the next.
                    if (!calEvent.serviceTypes[serviceTypeId]) continue;

                    var serviceWindow = {
                        tech_id: Number(__TECHNICIAN_ID__),
                        start: calEvent.start.format('H:mm:ss'),
                        end: calEvent.end.format('H:mm:ss'),
                        date: '00-00-0000 00:00:00',
                        service_type: Number(serviceTypeId),
                        day_of_week: Number(calEvent.start.format('d'))
                    };

                    serviceWindows.push(serviceWindow);
                }
        });

        function onSubmitSuccess() {
            $scope.serviceWindowForm.$submitted = false;
            var dialog = $mdDialog.alert()
                .title('Success!')
                .htmlContent("The default service windows for <strong>" + __TECHNICIAN_NAME__ + "</strong> have been saved.")
                .ariaLabel('save success')
                .ok('OK');
            $mdDialog.show(dialog);
        }

        handleAjaxSubmission('POST', '/admin/service-windows/' + __TECHNICIAN_ID__, onSubmitSuccess, serviceWindows);
    };

    function createTitleStringFromScope(scope) {
        return createTitleString(scope.serviceTypes);
    }

    function createServiceWindow(start, end, scope) {
        var id = generateTemporaryId();
        var title = createTitleStringFromScope(scope);

        $schedule.fullCalendar('renderEvent', {
            id: id,
            title: title,
            start: start,
            end: end,
            editable: true,
            resourceId: __TECHNICIAN_ID__,
            overlap: false,
            serviceTypes: scope.serviceTypes
        });
    }

    function updateServiceWindow(serviceWindow, scope) {
        serviceWindow.title = createTitleStringFromScope(scope);
        serviceWindow.serviceTypes = scope.serviceTypes;

        $schedule.fullCalendar('updateEvent', serviceWindow);
    }

    function deleteServiceWindow(serviceWindow, scope) {
        $schedule.fullCalendar('removeEvents', serviceWindow.id);
    }

    function noServiceTypeSelected (serviceTypes) {
        for (var id in serviceTypes) {
            if (serviceTypes[id]) return false;
        }
        return true;
    }

    $schedule.fullCalendar({
        allDaySlot: false,
        height: 626,
        defaultView: 'agendaWeek',
        selectable: true,
        schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
        header: false,
        columnFormat: 'dddd',
        scrollTime: '07:00:00',
        businessHours: {
            dow: [1, 2, 3, 4, 5],
            start: '8:00',
            end: '18:00'
        },
        events: [
            // get current service windows
        ],
        resources: [
            { id: __TECHNICIAN_ID__, title: __TECHNICIAN_NAME__ }
        ],
        eventClick: function(calEvent, jsEvent, view) {

            $mdDialog.show({
                clickOutsideToClose: true,
                scope: $scope,
                preserveScope: true,
                template: ($('#service-window-dialog')[0]).innerHTML,
                controller: function DialogController($scope, $mdDialog) {
                    $scope.editing = true;
                    $scope.serviceTypes = JSON.parse(JSON.stringify(calEvent.serviceTypes));

                    $scope.noServiceTypeSelected = function() {
                        return noServiceTypeSelected($scope.serviceTypes);
                    };

                    $scope.saveServiceWindow = function () {
                        $mdDialog.hide($scope);
                        updateServiceWindow(calEvent, $scope);
                    };

                    $scope.deleteServiceWindow = function () {
                        $mdDialog.hide($scope);
                        deleteServiceWindow(calEvent);
                    };
                }
            });
        },
        select: function(start, end, jsEvent, view, resource) {
            $mdDialog.show({
                clickOutsideToClose: true,
                scope: $scope,
                preserveScope: true,
                template: ($('#service-window-dialog')[0]).innerHTML,
                controller: function DialogController($scope, $mdDialog) {
                    $scope.editing = false;
                    $scope.serviceTypes = {};

                    $scope.noServiceTypeSelected = function() {
                        return noServiceTypeSelected($scope.serviceTypes);
                    };

                    $scope.saveServiceWindow = function () {
                        $mdDialog.hide($scope);
                        createServiceWindow(start, end, $scope);
                    };
                }
            })
        }
    });

    // Remove the "today" highlight.
    $('.fc-day').removeClass('fc-today');

    // Process existing service windows.
    var existingServiceWindows = [];
    var collectedIds = [];
    __SERVICE_WINDOWS__.forEach(function(a) {
        a.service_types = [a.service_type];

        // If we've already collected this item, then move to the next.
        if (collectedIds.indexOf(a.id) !== -1) return true;

        collectedIds.push(a.id);

        __SERVICE_WINDOWS__.forEach(function(b) {
            if (a.id !== b.id &&
                collectedIds.indexOf(b.id) === -1 &&
                a.start === b.start &&
                a.end === b.end &&
                a.day_of_week === b.day_of_week) {

                collectedIds.push(b.id);
                a.service_types.push(b.service_type);
            }
        });
        existingServiceWindows.push(a);
    });

    // Add existing service windows to the calendar.
    existingServiceWindows.map(function(serviceWindow) {
        // Translate start end and dates to this calendar.
        var start = moment(serviceWindow.start, "HH:mm:ss").day(serviceWindow.day_of_week);
        var end = moment(serviceWindow.end, "HH:mm:ss").day(serviceWindow.day_of_week);

        // Translate service types to hash.
        var serviceTypes = {};
        serviceWindow.service_types.forEach(function(serviceTypeId) {
            serviceTypes[serviceTypeId] = true;
        });

        var event = {
            id: generateTemporaryId(),
            title: createTitleString(serviceTypes),
            start: start.toDate(),
            end: end.toDate(),
            editable: true,
            resourceId: __TECHNICIAN_ID__,
            overlap: false,
            serviceTypes: serviceTypes
        };

        $schedule.fullCalendar('renderEvent', event);
    });
}]);

app.controller('AdminEquipmentController', ['$scope', '$log', '$mdDialog', '$http', '$q', function ($scope, $log, $mdDialog, $http, $q) {
    $log.debug('Loading AdminEquipmentController');

    var handleAjaxSubmission = makeAjaxHandler($mdDialog, $http);

    $scope.equipment = {};

    if (window.__SELECTED_CUSTOMER__) {
        $scope.selectedCustomer = window.__SELECTED_CUSTOMER__;
        $scope.selectedAddress = window.__SELECTED_ADDRESS__;
    }

    if (window.__INSTALLATION_DATE__) {
        $scope.equipment.installation_date = Date.fromMySQLDate(window.__INSTALLATION_DATE__);
    }

    $scope.createEquipment = function() {
        window.location.href = '/admin/create-equipment'
    };

    $scope.editEquipment = function(equipmentId) {
        window.location.href = '/admin/edit-equipment/' + equipmentId;
    };

    $scope.deleteEquipment = function(equipmentId) {
        var confirm = $mdDialog.confirm()
            .title('Delete equipment?')
            .htmlContent('This will permanently delete this equipment. Are you sure?')
            .ariaLabel('delete equipment')
            .ok('Yes, delete forever')
            .cancel('Cancel');

        $mdDialog.show(confirm).then(function() {
            handleAjaxSubmission('DELETE', '/admin/delete-equipment/' + equipmentId, true);
        });
    };
    
    $scope.submitEquipment = function() {
        if ($scope.equipmentForm.$pristine || !$scope.equipmentForm.$valid) {
            return;
        }
        $scope.equipmentForm.$submitted = true;
        $('[name="equipmentForm"]').submit();
    };

    $scope.findCustomers = function (query) {
        var deferred = $q.defer();
        (function (query, deferred) {
            makeAjaxCustomerFinder($mdDialog, $http, function(data) {
                $scope.customers = data;
                deferred.resolve(data);
            })(query);
        })(query, deferred);
        return deferred.promise;
    };

    // ----- Address Auto Complete ----
    addressAutoComplete('address', function (place) {
        $scope.equipment.address_id = '';
        $scope.place_id = place.place_id;
        $scope.plain_text = place.formatted_address;
        $scope.$apply();
    });
    // ----- Address Auto Complete ----

    $scope.findAddress = function (query) {
        var deferred = $q.defer();
        (function (query, deferred) {
            makeCustomerAddressFinder($mdDialog, $http, function(data) {
                $scope.customers = data;
                deferred.resolve(data);
            })($scope.selectedCustomer.id, query);
        })(query, deferred);
        return deferred.promise;
    };

    $scope.createNewAddress = function (addressQuery) {
        $scope.newAddress = true;
        setTimeout(function() {
            $scope.addressQuery = "";
            document.getElementById('address').value= addressQuery;
            document.getElementById('address').focus();
        }, 100);
    };

    Mousetrap($('[name="search"]')[0]).bind('enter', function() {
        $('[name="search"]').submit();
    });

    $scope.membershipLevelToString = window.membershipLevelToString;
}]);

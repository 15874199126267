app.controller('SettingsController', ['$scope', '$log', '$mdDialog', function ($scope, $log, $mdDialog) {
    $log.debug('Loading SettingsController');

    $scope.submitSettings = function() {
        if ($scope.userForm.$pristine || !$scope.userForm.$valid) {
            return;
        }
        $scope.userForm.$submitted = true;
        $('[name="userForm"]').submit();
    };

    Mousetrap($('[name="userForm"]')[0]).bind('enter', function() {
        $scope.submit();
    });
}]);

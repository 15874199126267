/**
 * The global Angular application.
 * @var app
 */
var app = angular.module(
'iChangeBillings',
['ngMaterial', 'ngMessages', 'ngSanitize', 'ui.tinymce']);

// Configure the application theming for Angular Material.
app.config(function ($mdThemingProvider) {

    //Custom Angular Material theme.
    //See https://material.angularjs.org/0.10.0/#/Theming/03_configuring_a_theme
    $mdThemingProvider.definePalette('ichange', {
        '50': '8cc6ff',
        '100': '4ca6ff',
        '200': '4ca6ff',
        '300': '4698ea',
        '400': '3f8ad4',
        '500': '397cbf',
        '600': '196cbf',
        '700': '055fa9',
        '800': '04477e',
        '900': '022f53',
        'A100': 'f9edb3',
        'A200': 'f9da9f',
        'A400': 'f9c460',
        'A700': 'f9b636',

        // whether, by default, text (contrast) on this palette should
        // be dark or light
        'contrastDefaultColor': 'light',

        // Hues which contrast; should be 'dark' by default.
        'contrastDarkColors': ['50', '100', '200', '300', '400', 'A100'],

        // could also specify this if default was 'dark'
        'contrastLightColors': undefined
    });

    $mdThemingProvider.theme('default').primaryPalette('ichange');
});

app.filter('minrange', function() {
  return function(input, total) {
    total = parseInt(total);

    for (var i=0; i<total; i++) {
        if (i < 10) {
            input.push('0' + i);
        } else {
            input.push(i);
        }
    }

    return input;
  };
});

app.filter('hourrange', function() {
  return function(input, total) {
    total = parseInt(total);

    for (var i=1; i<total + 1; i++) {
        input.push(i);
    }

    return input;
  };
});

Date.prototype.toMySQLDate = function() {
    var year, month, day;

    year = String(this.getFullYear());
    month = String(this.getMonth() + 1);
    day = String(this.getDate());
    hour = String(this.getHours());
    minute = String(this.getMinutes());
    second = String(this.getSeconds());

    if (month.length == 1) month = "0" + month;
    if (day.length == 1) day = "0" + day;
    if (hour.length == 1) hour = "0" + hour;
    if (minute.length == 1) minute = "0" + minute;
    if (second.length == 1) second = "0" + second;

    return year + "-" + month + "-" + day + ' ' + hour + ':' + minute + ':' + second;
};

Date.fromMySQLDate = function (dateString) {
    if (dateString.length == 0 || dateString == '0000-00-00 00:00:00') return null;
    return new Date(dateString);
};

//app.factory('$log', function () {
//    var consoleAppender, logger;
//    logger = log4javascript.getLogger('iChangeLogger');
//    consoleAppender = new log4javascript.PopUpAppender(null, true);
//    logger.addAppender(consoleAppender);
//
//    Mousetrap.bind('command+shift+l', function(e) {
//        consoleAppender.show();
//        return false;
//    });
//
//    return logger;
//});

app.controller('AdminAppointmentController', ['$rootScope', '$scope', '$log', '$mdDialog', '$http', '$q', function ($rootScope, $scope, $log, $mdDialog, $http, $q) {
    $log.debug('Loading AdminAppointmentController');

    var handleAjaxSubmission = makeAjaxHandler($mdDialog, $http);

    $scope.appointment = {};

    $scope.viewCustomerCharges = function(customerId) {
        window.location.href = '/admin/charges/' + customerId;
    };

    var setFromMySQLDate = function(se, dateTimeString) {
        if (dateTimeString.length === 0) return;

        var date = new Date(dateTimeString);
        var hours = date.getHours();
        var minutes = String(date.getMinutes());
        var meridiem = null;

        if (minutes.length == 1) minutes = '0' + minutes;

        if (hours > 11) {
            meridiem = 'pm';

            if (hours > 12) {
                hours = hours - 12;
            }
        } else {
            meridiem = 'am';
        }

        $scope.appointment[se + '_date'] = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        $scope.appointment[se + '_hour'] = String(hours);
        $scope.appointment[se + '_minute'] = minutes;
        $scope.appointment[se + '_meridiem'] = meridiem;
    };

    if (window.__START_DATETIME__) {
        setFromMySQLDate("start", window.__START_DATETIME__);
        setFromMySQLDate("end", window.__END_DATETIME__);
    }

    if (window.__SELECTED_CUSTOMER__) {
        $scope.selectedCustomer = window.__SELECTED_CUSTOMER__;
        $scope.selectedAddress = window.__SELECTED_ADDRESS__;
        $scope.appointment.notes = window.__NOTES__;
    }

    $scope.createAppointment = function() {
        window.location.href = '/admin/appointment'
    };

    $scope.editAppointment = function(appointmentId) {
        window.location.href = '/admin/appointment/' + appointmentId;
    };

    $scope.deleteAppointment = function(appointmentId) {
        var confirm = $mdDialog.confirm()
            .title('Delete appointment?')
            .htmlContent('This will permanently delete this appointment. Are you sure?')
            .ariaLabel('delete appointment')
            .ok('Yes, delete forever')
            .cancel('Cancel');

        $mdDialog.show(confirm).then(function() {
            handleAjaxSubmission('DELETE', '/admin/appointment/' + appointmentId, true);
        });
    };

    $scope.deleteAppointmentFromForm = function (appointmentId) {
        var confirm = $mdDialog.confirm()
            .title('Delete appointment?')
            .htmlContent('This will permanently delete this appointment. Are you sure?')
            .ariaLabel('delete appointment')
            .ok('Yes, delete forever')
            .cancel('Cancel');

        $mdDialog.show(confirm).then(function() {
            $scope.appointmentForm.$submitted = true;
            handleAjaxSubmission('DELETE', '/admin/appointment/' + appointmentId, function () {
                window.location.href = window.__REDIRECT__.length > 0 ? window.__REDIRECT__ : "/admin/appointments";
            });
        });
    };

    $scope.submitAppointment = function() {
        if ($scope.appointmentForm.$pristine || !$scope.appointmentForm.$valid) {
            return;
        }

        var startHour = +$scope.appointment.start_hour;

        if ($scope.appointment.start_meridiem === 'pm' && startHour < 12) {
            startHour += 12;
        }

        var endHour = +$scope.appointment.end_hour;

        if ($scope.appointment.end_meridiem === 'pm' && endHour < 12) {
            endHour += 12;
        }

        var startString = moment($scope.appointment.start_date).format("YYYY-MM-DD") + " " +
            startHour + ":" + $scope.appointment.start_minute;

        var start = new Date(startString);
        $scope.appointment.start = start.toMySQLDate();

        var endString = moment($scope.appointment.start_date).format("YYYY-MM-DD") + " " +
            endHour + ":" + $scope.appointment.end_minute;

        var end = new Date(endString);
        $scope.appointment.end = end.toMySQLDate();

        if (end < start) {
            var error = $mdDialog.alert()
                .title('Whoops!')
                .htmlContent('Looks like the end time is wrong. It\'s before the start time :)')
                .ariaLabel('error occurred')
                .ok('OK, I\'ll Fix It');

            $mdDialog.show(error);

            return;
        }

        $scope.appointmentForm.$submitted = true;
        $scope.appointment.customer_id = $scope.selectedCustomer.id;

        if (!$scope.newAddress) {
            $scope.appointment.address_id = $scope.selectedAddress.id;
        } else {
            $scope.appointment.address_id = '';
        }

        setTimeout(function() {
            $('[name="appointmentForm"]').submit();
        }, 500);
    };

    $scope.newAddress = false;
    $scope.createNewAddress = function (addressQuery) {
        $scope.newAddress = true;
        setTimeout(function() {
            $scope.addressQuery = "";
            document.getElementById('address').value= addressQuery;
            document.getElementById('address').focus();
        }, 100);
    };

    // ----- Address Auto Complete ----
    addressAutoComplete('address', function (place) {
        $scope.appointment.place_id = place.place_id;
        $scope.appointment.plain_text = place.formatted_address;
        $scope.$apply();
    });
    // ----- Address Auto Complete ----

    $scope.findAddress = function (query) {
        console.log('finding address for', $scope.selectedCustomer.id);
        var deferred = $q.defer();
        (function (query, deferred) {
            makeCustomerAddressFinder($mdDialog, $http, function(data) {
                $scope.customers = data;
                deferred.resolve(data);
            })($scope.selectedCustomer.id, query);
        })(query, deferred);
        return deferred.promise;
    };

    $scope.findCustomers = function (query) {
        var deferred = $q.defer();
        (function (query, deferred) {
            makeAjaxCustomerFinder($mdDialog, $http, function(data) {
                $scope.customers = data;
                deferred.resolve(data);
            })(query);
        })(query, deferred);
        return deferred.promise;
    };

    Mousetrap($('[name="search"]')[0]).bind('enter', function() {
        $('[name="search"]').submit();
    });

    $scope.membershipLevelToString = membershipLevelToString;
}]);

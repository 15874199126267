app.controller('AdminChargeController', ['$scope', '$log', '$mdDialog', '$http', function ($scope, $log, $mdDialog, $http) {
    $log.debug('Loading AdminChargeController');

    var handleAjaxSubmission = makeAjaxHandler($mdDialog, $http);

    $scope.createCharge = function(customer_id) {
        window.location.href = '/admin/charge/' + customer_id
    };

    $scope.refundCharge = function(charge_id) {
        var confirm = $mdDialog.confirm()
            .title('Refund?')
            .htmlContent('This will refund this charge. Are you sure?')
            .ariaLabel('refund charge')
            .ok('Yes, refund charge')
            .cancel('Cancel');

        $mdDialog.show(confirm).then(function() {
            handleAjaxSubmission('DELETE', '/admin/refund-charge/' + charge_id, true);
        });
    };

    $scope.submitCharge = function() {
        if ($scope.chargeForm.$pristine || !$scope.chargeForm.$valid) {
            return;
        }

        $scope.submitting = true;
        $scope.stripeError = null;

        Stripe.card.createToken({
            number: $scope.credit_card,
            cvc: $scope.cvc,
            exp_month: $scope.exp_month,
            exp_year: $scope.exp_year
        }, function (statusCode, stripeResponse) {

            if (statusCode === 200) {
                $('#token').val(stripeResponse.id);
                $('[name="chargeForm"]').submit();
            } else {
                $scope.submitting = false;
                $scope.stripeError = stripeResponse.error.message;
                $scope.$apply();
            }

        });

    };

}]);

app.controller('AdminServiceTypeController', ['$scope', '$log', '$mdDialog', '$http', function ($scope, $log, $mdDialog, $http) {

    var handleAjaxSubmission = makeAjaxHandler($mdDialog, $http);

    $scope.createServiceType = function() {
        window.location.href = '/admin/service-type'
    };

    $scope.editServiceType = function(serviceTypeId) {
        window.location.href = '/admin/service-type/' + serviceTypeId;
    };

    $scope.deleteServiceType = function(serviceType) {
        var confirm = $mdDialog.confirm()
            .title('Delete Service Type?')
            .htmlContent('This will permanently delete the service type <strong>' + serviceType.name + '</strong>. Are you sure?')
            .ariaLabel('delete service type')
            .ok('Yes, delete forever')
            .cancel('Cancel');

        $mdDialog.show(confirm).then(function() {
            handleAjaxSubmission('DELETE', '/admin/service-type/' + serviceType.id, true, serviceType);
        });
    };
    
    $scope.submitServiceType = function() {
        if ($scope.serviceTypeForm.$pristine || !$scope.serviceTypeForm.$valid) {
            return;
        }
        $scope.serviceTypeForm.$submitted = true;
        $('[name="serviceTypeForm"]').submit();
    };
}]);

app.controller('AdminUserController', ['$scope', '$log', '$mdDialog', '$http', function ($scope, $log, $mdDialog, $http) {
    $log.debug('Loading AdminUserController');

    function showEditCreateUserDialog() {
        $mdDialog.show({
            clickOutsideToClose: true,
            scope: $scope,        // use parent scope in template
            preserveScope: true,  // do not forget this if use parent scope
            // Since GreetingController is instantiated with ControllerAs syntax
            // AND we are passing the parent '$scope' to the dialog, we MUST
            // use 'vm.<xxx>' in the template markup
            template: ($('#user-dialog')[0]).innerHTML,
            controller: function DialogController($scope, $mdDialog) {
                $scope.closeDialog = function() {
                    $mdDialog.hide();
                }
            }
        });
    }

    $scope.createUser= function() {
        window.location.href = '/admin/user'
    };

    $scope.editUser = function(userId) {
        window.location.href = '/admin/user/' + userId;
    };

    function handleSubmission(method, url, fn, data) {
        var error = $mdDialog.alert()
            .title('This is embarrassing!')
            .htmlContent('Sorry! An error occurred. Please try again in a few minutes.<br/>If the error continues, please contact support.')
            .ariaLabel('error occurred')
            .ok('OK');

        var configObject = {
            method: method,
            url: url
        };

        if (data) {
            configObject.data = data;
        }

        $http(configObject).then(function successCallback(response) {
            if (fn && typeof fn === 'function') {
                fn.call(null);
            } else if (fn === true) {
                document.location.reload(true);
            }
        }, function errorCallback(response) {
            $mdDialog.show(error);
        });
    }

    $scope.deleteUser = function(user) {
        var confirm = $mdDialog.confirm()
            .title('Delete user?')
            .htmlContent('This will permanently delete the user account for <strong>' + user.name + '</strong>. Are you sure?')
            .ariaLabel('delete user')
            .ok('Yes, delete forever')
            .cancel('Cancel');

        $mdDialog.show(confirm).then(function() {
            handleSubmission('DELETE', '/admin/user/' + user.id, true, user);
        });
    };

    $scope.changeUserRole = function(user, role) {
        user.role = role;
        user.password = '';
        handleSubmission('POST', '/admin/user/' + user.id, true, user);
    };

    $scope.setServiceWindows = function(userId) {
        window.location.href = '/admin/service-windows/' + userId;
    };

    $scope.submitUser = function() {
        if ($scope.userForm.$pristine || !$scope.userForm.$valid) {
            return;
        }
        $scope.userForm.$submitted = true;
        $('[name="userForm"]').submit();
    };

    /**
     * Opens the reports menu.
     *
     * @param {object} mdOpenMenu - The menu to open.
     *
     * @param {object} ev - The menu to open.
     */
    $scope.openRoleMenu = function($mdOpenMenu, ev) {
        $mdOpenMenu(ev);
    };

    Mousetrap($('[name="usersSearch"]')[0]).bind('enter', function() {
        $('[name="usersSearch"]').submit();
    });
}]);

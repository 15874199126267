app.controller('ScheduleController', ['$scope', '$log', '$mdDialog', '$http', function ($scope, $log, $mdDialog, $http) {

    var $schedule = $('#schedule');

    // Expose globally so we can refetch from master.
    window.$schedule = $schedule;

    var handleAjaxSubmission = makeAjaxHandler($mdDialog, $http);

    $scope.gotoDate = function () {
        var fdate = moment($scope.selectedDate).format('YYYY-MM-DD');
        window.location.href = '/admin/schedule/?date=' + fdate;
    };

    function noServiceTypeSelected (serviceTypes) {
        for (var id in serviceTypes) {
            if (serviceTypes[id]) return false;
        }
        return true;
    }

    function createTitleString(serviceTypes) {
        var serviceArr = [];
        __SERVICE_TYPES__.forEach(function(serviceType) {
            if (serviceTypes[serviceType.id]) {
                serviceArr.push(serviceType.name);
            }
        });

        serviceArr = _.uniq(serviceArr);

        return "Service Window: " + serviceArr.join(', ');
    }

    function createTitleStringFromScope(scope) {
        return createTitleString(scope.serviceTypes);
    }

    function createServiceWindow(start, end, techId, scope) {
        var id = generateTemporaryId();
        var title = createTitleStringFromScope(scope);

        var serviceWindow = {
            id: id,
            title: title,
            start: start,
            end: end,
            editable: true,
            resourceId: techId,
            overlap: false,
            serviceTypes: scope.serviceTypes
        };

        // create new service window... or appointment.
        handleAjaxSubmission('POST', '/admin/ajax-create-service-window', function(res) {
            serviceWindow.serviceWindowIds = res.data;
            console.log(res);

            $schedule.fullCalendar('renderEvent', serviceWindow);
        }, serviceWindow);
    }

    function updateServiceWindow(serviceWindow, scope) {

        // Only update the title and service types if a scope is provided. If it's not provided, we're resizing
        // the event, or moving it.
        if (scope) {
            serviceWindow.title = createTitleStringFromScope(scope);
            serviceWindow.serviceTypes = scope.serviceTypes;
        }

        handleAjaxSubmission('POST', '/admin/ajax-update-service-window', function(res) {
            // Set the new service window IDs. Each time a service window is updated, new service window is created.
            serviceWindow.serviceWindowIds = res.data;

            $schedule.fullCalendar('updateEvent', serviceWindow);

            console.log(serviceWindow);
        }, serviceWindow);
    }

    function updateAppointment(appointment) {
        handleAjaxSubmission('POST', '/admin/ajax-update-appointment', function() {
            $schedule.fullCalendar('updateEvent', appointment);
            $schedule.fullCalendar('refetchEvents');
        }, appointment);
    }

    function deleteServiceWindow(serviceWindow, scope) {
        handleAjaxSubmission('POST', '/admin/ajax-delete-service-window', function(res) {
            $schedule.fullCalendar('removeEvents', serviceWindow.id);
        }, serviceWindow);
    }

    function deleteServiceWindowSilently(serviceWindow) {
        handleAjaxSubmission('POST', '/admin/ajax-delete-service-window', false, serviceWindow);
    }

    $schedule.fullCalendar({
        allDaySlot: false,
        height: 688,
        defaultView: 'agendaDay',
        defaultDate: __DATE__.length > 0 ? moment(__DATE__) : null,
        selectable: true,
        schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
        // header: true,
        scrollTime: '07:00:00',
        titleFormat: 'dddd, MMM Do, YYYY',
        businessHours: {
            dow: [1, 2, 3, 4, 5],
            start: '8:00',
            end: '18:00'
        },
        events: '/admin/ajax-schedule',
        resources: __TECHNICIANS__.map(function(technician) {
            return {
                id: technician.id,
                title: technician.name
            }
        }),
        eventClick: function(calEvent, jsEvent, view) {

            if (calEvent.appointment) {
                window.location.href = "/admin/appointment/" + calEvent.id +
                    "?redirect=" + encodeURIComponent(window.location.pathname + "?date=" + encodeURIComponent(calEvent.start.format('YYYY-MM-DD')));
                return;
            }

            if (calEvent.start.toDate() < moment().startOf('day')) return;

            $mdDialog.show({
                clickOutsideToClose: true,
                scope: $scope,
                preserveScope: true,
                template: ($('#service-window-dialog')[0]).innerHTML,
                controller: function DialogController($scope, $mdDialog) {
                    $scope.editing = true;
                    $scope.serviceTypes = JSON.parse(JSON.stringify(calEvent.serviceTypes));

                    $scope.noServiceTypeSelected = function() {
                        return noServiceTypeSelected($scope.serviceTypes);
                    };

                    $scope.saveServiceWindow = function () {
                        $mdDialog.hide($scope);
                        updateServiceWindow(calEvent, $scope);
                    };

                    $scope.deleteServiceWindow = function () {
                        $mdDialog.hide($scope);
                        deleteServiceWindow(calEvent);
                    };

                    $scope.convertToAppointment = function () {
                        var formattedStart = encodeURIComponent(calEvent.start.format('YYYY-MM-DD HH:mm:ss'));
                        var formattedEnd = encodeURIComponent(calEvent.end.format('YYYY-MM-DD HH:mm:ss'));

                        window.location.href = '/admin/appointment?tech_id=' + calEvent.resourceId +
                            '&start=' + formattedStart +
                            '&end=' + formattedEnd +
                            "&redirect=" + encodeURIComponent(window.location.pathname + "?date=" + encodeURIComponent(calEvent.start.format('YYYY-MM-DD')));
                    }
                }
            });

        },
        eventResize: function(calEvent) {
            if (calEvent.serviceWindow) {
                updateServiceWindow(calEvent);
            } else if (calEvent.appointment) {
                updateAppointment(calEvent);
            }
        },
        eventDragStart: function(calEvent) {
            if (calEvent.serviceWindow) {
                deleteServiceWindowSilently(calEvent);
            }
        },
        eventDragStop: function(calEvent) {
            if (calEvent.serviceWindow) {
                updateServiceWindow(calEvent);
            } else if (calEvent.appointment) {
                updateAppointment(calEvent);
            }
        },
        select: function(start, end, jsEvent, view, resource) {

            if (start.toDate() < moment().startOf('day')) return;

            var formattedStart = encodeURIComponent(start.format('YYYY-MM-DD HH:mm:ss'));
            var formattedEnd = encodeURIComponent(end.format('YYYY-MM-DD HH:mm:ss'));

            $mdDialog.show({
                clickOutsideToClose: true,
                scope: $scope,
                preserveScope: true,
                template: ($('#new-dialog')[0]).innerHTML,
                controller: function DialogController($scope, $mdDialog) {
                    $scope.createNewServiceWindow = function() {
                        $mdDialog.hide($scope);
                        showCreateServiceWindow();
                    };

                    $scope.createNewAppointment = function () {
                        window.location.href = '/admin/appointment?tech_id=' + resource.id +
                            '&start=' + formattedStart +
                            '&end=' + formattedEnd +
                            "&redirect=" + encodeURIComponent(window.location.pathname + "?date=" + encodeURIComponent(start.format('YYYY-MM-DD')));
                    };
                }
            });

            function showCreateServiceWindow() {
                $mdDialog.show({
                    clickOutsideToClose: true,
                    scope: $scope,
                    preserveScope: true,
                    template: ($('#service-window-dialog')[0]).innerHTML,
                    controller: function DialogController($scope, $mdDialog) {
                        $scope.editing = false;
                        $scope.serviceTypes = {};

                        $scope.noServiceTypeSelected = function() {
                            return noServiceTypeSelected($scope.serviceTypes);
                        };

                        $scope.saveServiceWindow = function () {
                            $mdDialog.hide($scope);

                            createServiceWindow(start, end, resource.id, $scope);
                        };
                    }
                });
            }
        }
    });

}]);

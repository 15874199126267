window.generateTemporaryId = function() {
    return 'tmp_' + Math.round(Math.random() * 1e6);
};

window.makeAjaxHandler = function($mdDialog, $http) {
    return function(method, url, fn, data) {
        var error = $mdDialog.alert()
            .title('This is embarrassing!')
            .htmlContent('Sorry! An error occurred. Please try again in a few minutes.<br/>If the error continues, please contact support.')
            .ariaLabel('error occurred')
            .ok('OK');

        var configObject = {
            method: method,
            url: url
        };

        if (data) {
            configObject.data = data;
        }

        $http(configObject).then(function successCallback(response) {
            if (fn && typeof fn === 'function') {
                fn.call(null, response);
            } else if (fn === true) {
                document.location.reload(true);
            }
        }, function errorCallback(response) {
            $mdDialog.show(error);
        });
    };
};


window.addressAutoComplete = function(elementId, changedCallback, placeholder) {
    var input = document.getElementById(elementId);

    if (!input) return;

    var defaultBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(45.787636, -108.489304),
        new google.maps.LatLng(45.787636, -108.489304));

    var options = {
        bounds: defaultBounds
    };

    autocomplete = new google.maps.places.Autocomplete(input, options);

    autocomplete.addListener('place_changed', function() {
        var place = autocomplete.getPlace();

        changedCallback.call(undefined, place);
    });

    document.getElementById(elementId).placeholder = placeholder || "Address";

    function triggerClick () {
        setTimeout(function () {
            $(input).blur();
        }, 300);
    }

    setInterval(function () {
        $('.pac-item:not(.click-bound)')
            .bind('touchstart', triggerClick)
            .addClass('click-bound');
    }, 300);
};


window.makeAjaxCustomerFinder = function($mdDialog, $http, callback) {
    var handler = makeAjaxHandler($mdDialog, $http);

    return function (query) {
        handler('GET', '/admin/find-customer?q=' + encodeURIComponent(query) , function (res) {
            callback.call(undefined, res.data);
        });
    }
};


window.makeCustomerAddressFinder = function($mdDialog, $http, callback) {
    var handler = makeAjaxHandler($mdDialog, $http);

    return function (customerId, query) {
        handler('GET', '/admin/find-customer-address?customerId=' + customerId + '&q=' + encodeURIComponent(query) , function (res) {
            callback.call(undefined, res.data);
        });
    }
};

window.membershipLevelToString = function (level) {
    switch (level) {
        case "sapphire":
            return "Sapphire";
        case "diamond":
            return "Diamond";
        case "sapphire-plus":
            return "Sapphire Plus";
        case "diamond-plus":
            return "Diamond Plus";
        case "do-not-service":
            return "Do Not Service";
        default:
            return "No Membership";
    }
};
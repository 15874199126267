app.controller('AuthController', ['$scope', '$log', function ($scope, $log) {
    $log.debug('Loading AuthController');

    $scope.registration = {
        name: ''
    };

    $scope.submit = function() {
        if ($scope.loginForm.$pristine || !$scope.loginForm.$valid) {
            return;
        }
        $('[name="loginForm"]').submit();
    };

    Mousetrap($('[name="loginForm"]')[0]).bind('enter', function() {
        $scope.submit();
    });

    // Registration Methods
    $scope.register = function() {
        if ($scope.registerForm.$pristine || !$scope.registerForm.$valid) {
            return;
        }
        $('[name="registerForm"]').submit();
    };

    $scope.termsOfUse = function(e) {
        var link = '/page/terms-of-use';
        window.open(link, 'Terms of Use', 'width=700, height=700');
    }

    $scope.privacyPolicy = function(e) {
        var link = '/page/privacy-policy';
        window.open(link, 'Privacy Policy', 'width=700, height=700');
    }

    Mousetrap($('[name="registerForm"]')[0]).bind('enter', function() {
        $scope.register();
    });
}]);
